import React from 'react';
import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import "./Home.css";
import { Navigate, Routes, Route } from 'react-router-dom';
import Home from "./Home";
import ChatRoom from "./ChatRoom";
import Settings from "./Settings";
import Case from "./Case";


const initialSessions = [
    {
        title: '新的聊天',
        createTime: '',
        messages: [],
    }
];

export default function () {
    return (
        <Routes>
            <Route path='/' element={<Navigate to='/home' />}></Route>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/chat" element={<ChatRoom />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/case" element={<Case />} />
            {/* <Route path="*" element={<NotFound/>}/> */}
        </Routes>
    );
}
