import React, { useState, useEffect } from 'react';
import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import "./Settings.css";
import band from './fland.png';
import { Button, Icon, IconButton, Flex, FlexItem, Text, List, ListItem, Image, Modal } from '@chatui/core';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { db } from './db';
import moment from 'moment'
import useLongPress from "./useLongPress";

export default function () {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

    function handleHome(chatId) {
        navigate('/home');
    }

    function handleEmpty(chatId) {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleConfirm() {
        db.aisessions.clear();
        setOpen(false);
    }

    return (
        <div class="wrap">
            <div class="header">
                <h3 className='headerTitle'>设置</h3>
                <IconButton icon="chevron-left" size="lg" className='headerBack' onClick={() => handleHome()} />
            </div>

            <div class="main">
                <List>
                    <ListItem content="清空聊天" rightIcon="chevron-right" onClick={() => handleEmpty()} />
                </List>
            </div>

            <Modal
                active={open}
                title="提示"
                showClose={false}
                onClose={handleClose}
                actions={[
                    {
                        label: '确认',
                        color: 'primary',
                        onClick: handleConfirm,
                    },
                    {
                        label: '取消',
                        onClick: handleClose,
                    },
                ]}
            >
                <p style={{ paddingLeft: '15px' }}>确定要清空所有聊天吗?</p>
            </Modal>
        </div>
    );
}
