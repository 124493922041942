import Dexie from 'dexie';

class MyDatabase extends Dexie {
  constructor() {
    super('MyDatabase');
    this.version(1).stores({
      aisessions: '++id, title, messages, createTime',
    });
  }
}

export const db = new MyDatabase();
