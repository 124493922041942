import React from 'react';
import Chat, { Bubble, Modal, useMessages, Input } from '@chatui/core';
import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import axios from 'axios';
import { useState, useEffect } from 'react';
import aiAvatar from './app-logo.svg';
import { Avatar } from '@chatui/core';
import band from './band.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from './db';
import moment from 'moment'
import "./ChatRoom.css"

const initialMessages = [
  {
    type: 'text',
    content: { text: '您好，我是富澜德智能助理，你的贴心小助手~' },
    user: { avatar: aiAvatar },
    // user: { avatar: band },
  }
];

// 默认快捷短语，可选
const defaultQuickReplies = [
  {
    name: 'Hello AI',
  },
  {
    // icon: 'message',
    name: '修改标题',
  },
  {
    // icon: 'message',
    name: '删除聊天',
  },
];

const initAiMessages = [
  {
    "role": "system",
    "content": "You are a helpful assistant."
  },
];

export default function () {
  // 消息列表
  const { messages, appendMsg, setTyping } = useMessages(initialMessages);
  const [aiMessages, setAiMessages] = useState([]);
  const navigate = useNavigate();
  const params = useLocation().state;
  const [navbarParams, setNavbarParams] = useState();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [openTitle, setOpenTitle] = useState(false);

  const myNavbarParams = {
    className: 'chatNavbar',
    title: '新的聊天',
    leftContent: {
      icon: 'chevron-left',
      title: 'Back',
      onClick() {
        navigate('/home');
      },
    },
    // rightContent: [
    //   {
    //     icon: 'close',
    //     title: '删除',
    //     onClick() {
    //       handleOpen();
    //     },
    //   },
    // ],
  }

  useEffect(() => {
    async function fetchData() {
      const chat = await db.aisessions.get(params.id);
      setTitle(chat.title);
      myNavbarParams.title = chat.title;
      setNavbarParams(myNavbarParams);
      setAiMessages(chat.messages);
      chat.messages.map(function (msg) {
        appendMsg({
          type: 'text',
          content: { text: msg.content },
          position: msg.role === 'user' ? 'right' : 'left',
          user: { avatar: msg.role === 'user' ? '' : aiAvatar },
        });
      });
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (aiMessages.length > 0) {
      db.aisessions.update(params.id, { messages: aiMessages });
    }
  }, [aiMessages]);

  // 发送回调
  function handleSend(type, val) {
    if (type === 'text' && val.trim()) {
      appendMsg({
        type: 'text',
        content: { text: val },
        position: 'right',
      });

      setTyping(true);
      let data = [...aiMessages, { role: "user", content: val }];

      // axios.get('http://localhost:5000/chat')
      axios.post('https://gpt-server.flandiot.com/chat', data)
        .then(async function (response) {
          setAiMessages([...data, { role: "assistant", content: response.data }]);

          // 模拟回复消息
          appendMsg({
            type: 'text',
            content: { text: response.data },
            position: 'left',
            user: { avatar: aiAvatar },
          });
        })
        .catch(function (error) {
          appendMsg({
            type: 'text',
            content: { text: error },
          });
        });
    }
  }

  // 快捷短语回调，可根据 item 数据做出不同的操作，这里以发送文本消息为例
  function handleQuickReplyClick(item) {
    if (item.name === '修改标题') {
      setOpenTitle(true);
    }
    else if (item.name === '删除聊天') {
      handleOpen(true);
    }
    else {
      handleSend('text', item.name);
    }
  }

  // 删除聊天
  function handleDelete() {
    db.aisessions.delete(params.id);
    navigate('/home');
  }

  function renderMessageContent(msg) {
    const { type, content } = msg;

    // 根据消息类型来渲染
    switch (type) {
      case 'text':
        return (
          <div>
            {/* <Avatar src={aiAvatar} shape="square" size="sm" /> */}
            <Bubble content={content.text} />
          </div>
        );
      case 'image':
        return (
          <Bubble type="image">
            <img src={content.picUrl} alt="" />
          </Bubble>
        );
      default:
        return null;
    }
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    handleDelete();
    setOpen(false);
  }

  function handleSaveTitle() {
    db.aisessions.update(params.id, { title: title });
    
    myNavbarParams.title = title;
    setNavbarParams(myNavbarParams);

  
    setOpenTitle(false);
  }

  function handleTitleClose() {
    setOpenTitle(false);
  }
    
  return (
    <div style={{ height: "100%" }}>
      <Chat
        navbar={navbarParams}
        messages={messages}
        renderMessageContent={renderMessageContent}
        quickReplies={defaultQuickReplies}
        onQuickReplyClick={handleQuickReplyClick}
        onSend={handleSend}
      />
      <Modal
        active={open}
        title="提示"
        showClose={false}
        onClose={handleClose}
        actions={[
          {
            label: '确认',
            color: 'primary',
            onClick: handleConfirm,
          },
          {
            label: '取消',
            onClick: handleClose,
          },
        ]}
      >
        <p style={{ paddingLeft: '15px' }}>确定要删除吗?</p>
      </Modal>

      <Modal
        active={openTitle}
        title="修改标题"
        showClose={false}
        onClose={handleClose}
        actions={[
          {
            label: '保存',
            color: 'primary',
            onClick: handleSaveTitle,
          },
          {
            label: '取消',
            onClick: handleTitleClose,
          },
        ]}
      >
        <h3>标题</h3>
        <Input value={title} onChange={val => setTitle(val)} placeholder="请输入..." />
      </Modal>
    </div>
  );
}
