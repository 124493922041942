import React, { useState, useEffect } from 'react';
import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import "./Case.css";
import band from './fland.png';
import { Button, Icon, IconButton, Flex, Card, CardTitle, List, CardActions, CardText, Modal } from '@chatui/core';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { db } from './db';
import moment from 'moment'
import useLongPress from "./useLongPress";

export default function () {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    function handleHome(chatId) {
        navigate('/home');
    }

    function handleEmpty(chatId) {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleConfirm() {
        db.aisessions.clear();
        setOpen(false);
    }

    return (
        <div class="wrap">
            <div class="header">
                <h3 className='headerTitle'>场景</h3>
                <IconButton icon="chevron-left" size="lg" className='headerBack' onClick={() => handleHome()} />
            </div>

            <div class="main">
                <List className='myList'>
                    <Card size="xl">
                        <CardTitle>冷库报价</CardTitle>
                        <CardText>冷库容量的大小与冷库建筑面积和项目造价密切相关,为了能够给客户提供准确的方案预算,就需要用到冷库吨位与冷库容量换算,针对以上问题,中智物流咨询梳理了冷库吨位与冷库容量的换算方法。</CardText>
                        <CardActions>
                            <Button color="primary">设为默认</Button>
                        </CardActions>
                    </Card>
                    <Card size="xl">
                        <CardTitle>冷库知识</CardTitle>
                        <CardText>冷库作为一种重要的冷链物流设施，在食品、医药、化工等多个领域发挥着关键作用。</CardText>
                        <CardActions>
                            <Button color="primary">设为默认</Button>
                        </CardActions>
                    </Card>
                </List>
            </div>

            <Modal
                active={open}
                title="提示"
                showClose={false}
                onClose={handleClose}
                actions={[
                    {
                        label: '确认',
                        color: 'primary',
                        onClick: handleConfirm,
                    },
                    {
                        label: '取消',
                        onClick: handleClose,
                    },
                ]}
            >
                <p style={{ paddingLeft: '15px' }}>确定要清空所有聊天吗?</p>
            </Modal>
        </div>
    );
}
