import React, { useState, useEffect } from 'react';
import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import "./Home.css";
import band from './fland.png';
import { Button, IconButton, Flex, FlexItem, Text, List, ListItem, Image, Empty } from '@chatui/core';
import { useNavigate, Routes, Route } from 'react-router-dom';
import { db } from './db';
import moment from 'moment'
import useLongPress from "./useLongPress";

const initialSessions = [
  {
    title: '新的聊天',
    messages: [],
    createTime: '刚刚',
  }
];

export default function () {
  // 会话列表
  const [sessions, setSessions] = useState(initialSessions);
  const navigate = useNavigate();
  const onLongPress = () => {
    console.log('longpress is triggered');
  };

  const onClick = () => {
    console.log('click is triggered')
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  function emptyChat() {
    db.aisessions.clear();
  }

  function removeChat() {
  }

  async function handleNewChat() {
    db.aisessions.add({ title: '新的聊天', messages: [], createTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') });
    const chat = await db.aisessions.orderBy('id').reverse().limit(1).first();

    navigate('/chat', {
      state: { id: chat.id },
    });
  }

  function handleSettings(chatId) {
    navigate('/settings');
  }

  function handleCase(chatId) {
    navigate('/case');
  }

  function handleOldChat(chatId) {
    navigate('/chat', {
      state: { id: chatId },
    });
  }

  useEffect(() => {
    async function fetchData() {
      const list = await db.aisessions.orderBy('createTime').reverse().toArray();
      setSessions(list);
    }
    fetchData();
  }, []);

  let initContent;
  if (sessions.length == 0) {
    initContent = <Empty type="info" tip="富澜德智能助理">
      <Button color="primary" onClick={() => handleNewChat()}>开始聊天</Button>
    </Empty>
  } else {
    initContent = <List className='sessionList'>
      {sessions.map((item, index) => (
        <ListItem key={index} onClick={() => handleOldChat(item.id)} >
          <div className='sessionTitle'>{item.title}</div>
          <div className='sessionMsgSize'>{item.messages.length} 条对话</div>
          <div className='sessionTime'>{item.createTime}</div>
        </ListItem>
      ))}
    </List>
  }


  return (
    <div class="wrap">
      <div class="header">
        <Image src={band} className='logo'></Image>
        <IconButton icon="plus" size="lg" className='headerNewMessage' onClick={() => handleNewChat()} />
        <h3 className='headerTitle'>富澜德智能助理</h3>
        <div className='headerDesc'>您的贴心小助手</div>
        <Flex center className='settingPanel'>
          <Button size="lg" className='settingPanelButton' onClick={() => handleCase()}>场景</Button>
          <Button size="lg" className='settingPanelButton' onClick={() => handleSettings()}>设置</Button>
        </Flex>
      </div>

      <div class="main">
        {initContent}
      </div>

      {/* <div class="footer">
        <Flex style={{ height: '100%' }}>
          <IconButton icon="message" size="lg" className='footerBtn' />
          <IconButton icon="mic" size="lg" className='footerBtn' />
          <IconButton icon="image" size="lg" className='footerBtn' />
          <IconButton icon="share" size="lg" className='footerBtn' />
          <IconButton icon="apps" size="lg" className='footerBtn' />
        </Flex>
      </div> */}
    </div>
  );
}
